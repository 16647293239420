import 'bootstrap/dist/css/bootstrap.css'
import './src/layouts/styles.css'

export const onClientEntry = () => {
  window.initMap = () => {
    console.log('Map Loaded')
  }
  const script = document.createElement('script')
  script.src =
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_API_KEY}&libraries=places&callback=initMap`
  script.async = true
  script.defer = true
  document.body.appendChild(script)
}
